import type { AppFunnelInject } from '~/utils/provideKeys'

export const useAppFunnelInject = () => {
  const {
    loading,
    currentStep,
    onStepSuccess,
  } = inject<AppFunnelInject>(appFunnelKey, {
    loading: ref<boolean>(false),
    currentStep: computed(() => undefined),
    onStepSuccess: () => Promise.resolve(),
  })

  return {
    loading,
    currentStep,
    onStepSuccess,
  }
}
